import { useCallback } from "react";

import * as FullStory from "@fullstory/browser";

import { User } from "../typescript/graphql";

export function useSetFullstoryUser() {
  const setFullStoryUser = useCallback(
    (user: Pick<User, "id" | "username" | "firstName" | "lastName">) => {
      try {
        FullStory.identify(
          process.env.REACT_APP_FULL_STORY_USER_PREFIX! + user.id,
          {
            email: user.username,
            displayName: user.firstName + " " + user.lastName,
          }
        );
      } catch (ex) {
        console.error("Could not set user for fullstory", ex);
      }
    },
    []
  );

  return setFullStoryUser;
}

import React from "react";

import * as FullStory from "@fullstory/browser";
import ReactDOM from "react-dom";
import { IntercomProvider } from "react-use-intercom";

import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SentryBoundary from "./utils/SentryErrorBoundary";

let isInternalUser = false;
try {
  const loggedInUser = localStorage.getItem("user");
  if (loggedInUser && JSON.parse(loggedInUser).email.match(/@kloud\.io$/)) {
    isInternalUser = true;
  }
} catch (ex) {
  // Ignore
}

const fullStoryEnabled = process.env.REACT_APP_ENABLE_FULL_STORY === "true";
FullStory.init({
  orgId: process.env.REACT_APP_FULL_STORY_ORG!,
  devMode: isInternalUser || !fullStoryEnabled,
});

const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID!;
const shouldInitializeIntercom = !!(intercomAppId && intercomAppId !== "false");

ReactDOM.render(
  <React.StrictMode>
    <SentryBoundary fallback={"An error has occurred"}>
      <IntercomProvider
        appId={intercomAppId}
        autoBoot
        shouldInitialize={shouldInitializeIntercom}
      >
        <App />
      </IntercomProvider>
    </SentryBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

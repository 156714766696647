import React, { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { Layout, Steps, Button } from "antd";
import { useHistory } from "react-router-dom";

import { onboardingStateVar } from "../../apollo/cache";
import {
  GetUserOnboardingQuery,
  useGetUserOnboardingQuery,
  useLogoutMutation,
  useUpdateOnboardingMutation,
} from "../../typescript/graphql";
import style from "./style.module.scss";
import { clearLoginCache } from "../../utils/user";

const { Header, Sider, Content } = Layout;
const { Step } = Steps;

type UserType = GetUserOnboardingQuery["me"];

const OnboardingLayout: React.FC<{ children?: React.FC; title?: string }> = ({
  children,
}) => {
  const history = useHistory();
  const [logout, { loading: logoutLoading }] = useLogoutMutation();
  const [updateOnboarding] = useUpdateOnboardingMutation();
  const [me, setMe] = useState<UserType>();
  const { data, loading } = useGetUserOnboardingQuery();

  useEffect(() => {
    setMe(data?.me);
  }, [data?.me]);

  const onboardingState = useReactiveVar(onboardingStateVar);
  const { currentStep } = onboardingState;

  const onBoardingSteps = [
    { title: "Welcome", description: "Get started with Kloudio" },
    {
      title: "Select Data Warehouse",
      description: "Choose where you data is and will be stored",
    },
    {
      title: "Select Data Source",
      description: "Choose and authenticate apps or databases",
    },
    { title: "Invite Team Members", description: "Spread the love" },
    {
      title: "Start Using Kloudio",
      description: "With great power comes great responsibility",
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      await clearLoginCache();
    } catch (error) {}
  };

  const skipOnboarding = async () => {
    if (me) {
      await updateOnboarding({
        variables: {
          input: {
            id: me?.id,
            onboardingState: { ...onboardingState, completed: true },
          },
        },
      });
      history.push("/");
      window.location.reload();
    }
  };

  return (
    <Layout className={style.onboardingLayout}>
      <Header className={style.onboardingHeader}>
        <div className={style.onboardingLayoutLogoContainer}>
          <img
            src="/images/kloudio-logo-small.svg"
            alt="kloudio Logo"
            style={{ height: "50px", width: "auto" }}
          />
        </div>
        <div>
          <Button onClick={skipOnboarding} loading={loading}>
            Skip Onboarding
          </Button>
          <Button
            type="text"
            style={{ color: "white" }}
            loading={logoutLoading}
            onClick={handleLogout}
          >
            Save and Sign Out
          </Button>
        </div>
      </Header>
      <Layout>
        <Sider width="357px">
          <div className={style.onboardingSider}>
            <Steps
              direction="vertical"
              current={currentStep - 1}
              className={style.onboardingSiderSteps}
            >
              {onBoardingSteps.map((el, i) => {
                return (
                  <Step
                    key={i}
                    title={el.title}
                    description={el.description}
                    style={{ marginRight: "0px", width: "235px" }}
                  />
                );
              })}
            </Steps>
          </div>
        </Sider>
        <Content className={style.onboardingContent}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default OnboardingLayout;

import React, { Fragment, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { Spin } from "antd";
import NProgress from "nprogress";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import {
  isLoggedInVar,
  isPlanExpiredVar,
  OnboardingDefaultValue,
  onboardingStateVar,
} from "../apollo/cache";
import {
  useGetPlanQuery,
  useGetUserInfoQuery,
  useGetUserOnboardingQuery,
  UserInfoFragment,
} from "../typescript/graphql";
import AuthLayout from "./Auth";
import ConnectionLayout from "./Connection";
import Invite from "./Invite";
import MainLayout from "./Main";
import MessageView from "./MessageView";
import NewReportLayout from "./NewReport";
import OnboardingLayout from "./Onboarding";
import { PageRoutes } from "../router/constant";
import { useQueryParams } from "../hooks/useQueryParams";

const layouts = {
  onboarding: OnboardingLayout,
  auth: AuthLayout,
  main: MainLayout,
  messageView: MessageView,
  connection: ConnectionLayout,
  newReport: NewReportLayout,
  invite: Invite,
};
let previousPath = "";

const Layout: React.FC<{ children: React.FC; location: any }> = ({
  children,
}) => {
  const { shutdown, show, boot } = useIntercom();
  const history = useHistory();
  const query = useQueryParams();
  const { pageLoading } = useSelector((state: any) => state.report);
  const isPlanExpired = useReactiveVar(isPlanExpiredVar);

  let { pathname, search } = useLocation();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const { data: planData } = useGetPlanQuery({ skip: !isLoggedIn });

  const { data: onboardingData } = useGetUserOnboardingQuery({
    skip: !isLoggedIn,
  });

  const { data: userInfo } = useGetUserInfoQuery({
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (isPlanExpired) {
      history.push("/dashboard");
      show();
    }
  }, [isPlanExpired, history, show]);

  useEffect(() => {
    if (planData?.myAccount.expiresAt) {
      const expiryDate = new Date(planData.myAccount.expiresAt);
      if (expiryDate < new Date()) {
        isPlanExpiredVar(true);
      }
    }
  }, [planData]);

  useEffect(() => {
    if (isLoggedIn && onboardingData && !/^\/oauthCallback/.test(pathname)) {
      const onboardingState = onboardingData.me?.onboardingState;
      onboardingStateVar(onboardingState || OnboardingDefaultValue);
      if (!onboardingState) {
        history.push("/onboarding");
      } else if (!onboardingState.completed) {
        history.push("/onboarding");
      }
    }

    if (pathname === PageRoutes.Register && query.get("signup") != "true") {
      history.push(PageRoutes.Login);
    }

    if (
      pathname === PageRoutes.Onboarding &&
      onboardingData?.me.onboardingState?.completed
    ) {
      history.push(PageRoutes.Home);
    }

    if (!isLoggedIn) {
      shutdown(); // clears Intercom
    }
  }, [onboardingData, history, isLoggedIn, pathname, shutdown]);

  useEffect(() => {
    if (isLoggedIn && userInfo?.me?.email) {
      const user: UserInfoFragment = userInfo.me;

      // set intercom data
      boot({
        userHash: user.intercomHash,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        company: {
          companyId: "" + user.account.id,
          name: user.account.companyName,
          plan: user.account.plan.id,
          userCount: user.account.stats.userCount,
          customAttributes: {
            expiresAt: user.account.expiresAt,
          },
        },
      });
    }
  }, [boot, isLoggedIn, userInfo]);

  // NProgress & ScrollTop Management
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0);
    NProgress.start();
  }
  setTimeout(() => {
    NProgress.done();
    previousPath = currentPath;
  }, 300);

  const getLayout = (): keyof typeof layouts => {
    if (
      /^\/auth(?=\/|$)/i.test(pathname) ||
      /^\/oauthCallback/.test(pathname) ||
      pathname === "/addon-download" ||
      pathname === "/excel-addon-download"
    ) {
      return "auth";
    }
    if (pathname === "/onboarding") {
      return "onboarding";
    }

    if (pathname === "/acceptInvite") {
      return "messageView";
    }

    if (pathname === "/source") {
      return "connection";
    }

    if (pathname === "/openInvitation") {
      return "invite";
    }

    if (
      pathname === "/newReport" ||
      pathname === "/newReportSql" ||
      pathname.startsWith("/editReport") ||
      pathname.startsWith("/previewReport")
    ) {
      return "newReport";
    }

    return "main";
  };

  const Container = layouts[getLayout()];
  const isAuthLayout = getLayout() === "auth";

  const BootstrappedLayout = () => {
    const titleLookup: { [key: string]: string } = {
      "/dashboard": "Dashboard",
      "/connections": "Connections",
      "/flows": "Data Flows",
      "/reports": "Reports",
      "/schedules": "Schedules",
      "/templates": "Templates",
      "/users": "Users",
      "/settings": "Settings",
      "/admin-settings": "Account Settings",
      "/my-profile": "My Profile",
      "/billing": "Billing & Invoices",
      "/datasets": "Datasets",
      "/faq": "FAQ & Help",
      "/admin": "Admin Console",
      "/teams": "Teams",
      "/upgrade": "Upgrade Plan",
    };

    // show loader when user in check authorization process, not authorized yet and not on login pages
    // if (loading && !isLoggedIn && !isAuthLayout) {
    //   return null;
    // }
    // redirect to login page if current is not login page and user not authorized and not in the open invite page
    if (!isAuthLayout && !isLoggedIn && !(getLayout() === "invite")) {
      return <Redirect to="/auth/login" />;
    }
    // in other case render previously set layout
    return (
      <Container
        title={`${
          titleLookup[pathname]
            ? titleLookup[pathname]
            : /\/addMembers(?=\/|$)/i.test(pathname)
            ? "Add Members"
            : ""
        }  `}
      >
        {children}
      </Container>
    );
  };

  return (
    <Fragment>
      <Helmet titleTemplate="Kloudio | %s" />
      <Spin size="large" tip="Loading..." spinning={pageLoading && isLoggedIn}>
        {BootstrappedLayout()}
      </Spin>
    </Fragment>
  );
};

export default Layout;

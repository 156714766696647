import React, { lazy, Suspense } from "react";

import {
  Route,
  Redirect,
  Switch,
  BrowserRouter,
  useHistory,
} from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Layout from "../layouts";

const routes = [
  {
    path: "/auth/login",
    Component: lazy(() => import("../components/auth/Login")),
    exact: true,
  },
  {
    path: "/auth/alationOAuth",
    Component: lazy(() => import("../components/auth/AlationOAuth")),
    exact: true,
  },
  {
    path: "/auth/redirectToAlation",
    Component: lazy(() => import("../components/auth/RedirectToAlation")),
    exact: true,
  },
  {
    path: "/auth/alationOAuthCallback",
    Component: lazy(() => import("../components/auth/AlationOAuthCallback")),
    exact: true,
  },
  {
    path: "/auth/redirectToSnowflake",
    Component: lazy(() => import("../components/auth/RedirectToSnowflake")),
    exact: true,
  },
  {
    path: "/auth/snowflakeOAuthCallback",
    Component: lazy(() => import("../components/auth/SnowflakeOAuthCallback")),
    exact: true,
  },
  {
    path: "/auth/register",
    Component: lazy(() => import("../components/auth/Register")),
    exact: true,
  },
  {
    path: "/addon-download",
    Component: lazy(() => import("../components/AddonRedirect")),
  },
  {
    path: "/excel-addon-download",
    Component: lazy(() => import("../components/ExcelAddInRedirect")),
  },
  {
    path: "/oauthCallback/:id",
    Component: lazy(() => import("../components/auth/OauthCallbackPopup")),
  },
  {
    path: "/auth/gsheet-oauth",
    Component: lazy(() => import("../components/auth/GSheetOauth/GSheetOauth")),
  },
  {
    path: "/auth/google-sign-in-callback",
    Component: lazy(() =>
      import("../components/auth/GoogleSignInCallback/GoogleSignInCallback")
    ),
  },
  {
    path: "/auth/forgot-password",
    Component: lazy(() => import("../components/auth/ForgotPassword")),
    exact: true,
  },
  {
    path: "/auth/resetPassword",
    Component: lazy(() => import("../components/auth/ResetPassword")),
    exact: true,
  },
  {
    path: "/acceptInvite",
    Component: lazy(() => import("../components/users/AcceptInvite")),
  },
  {
    path: "/openInvitation",
    Component: lazy(() => import("../components/users/OpenInvitation")),
  },
  {
    path: "/onboarding",
    Component: lazy(() => import("../components/onboarding/Main")),
    exact: true,
  },
  {
    path: "/dashboard",
    Component: lazy(() => import("../components/dashboard")),
    exact: true,
  },
  {
    path: "/flows",
    Component: lazy(() => import("../components/connections/sources")),
    exact: true,
  },
  {
    path: "/connections",
    Component: lazy(() => import("../components/connections")),
    exact: true,
  },
  {
    path: "/users",
    Component: lazy(() => import("../components/users")),
    exact: true,
  },
  {
    path: "/teams/:teamId/AddMembers",
    Component: lazy(() => import("../components/Teams/AddMembers")),
  },
  {
    path: "/teams",
    Component: lazy(() => import("../components/Teams")),
  },
  {
    path: "/reports",
    Component: lazy(() => import("../components/reports/ReportsList")),
    exact: true,
  },
  {
    path: "/newReport",
    Component: lazy(() => import("../components/reports/NewReport")),
    exact: true,
  },
  {
    path: "/editReport/:id",
    Component: lazy(() => import("../components/reports/NewReport")),
    exact: true,
  },
  {
    path: "/previewReport/:id",
    Component: lazy(() => import("../components/reports/NewReport")),
    exact: true,
  },
  {
    path: "/newReportSql",
    Component: lazy(() => import("../components/reports/NewReport")),
    exact: true,
  },
  {
    path: "/schedules",
    Component: lazy(() => import("../components/schedules")),
    exact: true,
  },
  {
    path: "/my-profile",
    Component: lazy(() => import("../components/my-profile")),
  },
  {
    path: "/settings",
    Component: lazy(() => import("../components/settings")),
  },
  {
    path: "/source",
    Component: lazy(() => import("../components/warehouses")),
  },
  {
    path: "/admin",
    Component: lazy(() => import("../components/admin")),
  },
  {
    path: "/datasets",
    Component: lazy(() => import("../components/datasets")),
  },
  {
    path: "/billing/upgrade/:planId",
    Component: lazy(() => import("../components/billing/upgrade")),
  },
  {
    path: "/billing",
    Component: lazy(() => import("../components/billing")),
  },
  {
    path: "/admin-settings",
    Component: lazy(() => import("../components/settings/admin")),
  },
];

const Router = () => {
  const history = useHistory();
  const routerAnimation = "slide-fadein-up";

  return (
    <BrowserRouter
      history={history}
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  enter
                  classNames={routerAnimation}
                  timeout={routerAnimation === "none" ? 0 : 300}
                >
                  <Switch location={location}>
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </BrowserRouter>
  );
};

export default Router;

import { ApolloClient, ApolloLink, createHttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

import {
  cache,
  isLoggedInVar,
  OnboardingDefaultValue,
  onboardingStateVar,
  internalErrorsVar,
} from "./cache";
import { typeDefs } from "./typedefs";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors?.length) {
    graphQLErrors.forEach(({ message: messageText, path, extensions }) => {
      if (extensions?.code === "UNAUTHENTICATED") {
        isLoggedInVar(false);
        onboardingStateVar(OnboardingDefaultValue);
        localStorage.removeItem("user");
      }
      if (extensions?.code === "INTERNAL_SERVER_ERROR") {
        const error = {
          title: operation.operationName,
          extensions,
          path: path?.toString()!,
        };
        if (typeof Sentry?.captureException === "function") {
          Sentry.captureException(extensions?.exception);
        }
        internalErrorsVar([...internalErrorsVar(), error]);
        console.log(extensions.exception?.stacktrace?.join("\r\n"));
      }

      console.log(
        `[GraphQL error]: Message: ${messageText}, Operation: ${operation.operationName}, Path: ${path}`
      );
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        userId: JSON.parse(localStorage.getItem("user") ?? "{}")?.id,
        location: window.location.href,
      },
    };
  });
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const client = new ApolloClient({
  name: `kloudio-app-${process.env.REACT_APP_ENVIRONMENT}`,
  version: "2.0",
  connectToDevTools: true,
  link: from([cleanTypeName, errorLink, httpLink]),
  cache,
  typeDefs,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      errorPolicy: "all",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;

import React from "react";

import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
// import { createBrowserHistory } from "history";

if (process.env.REACT_APP_KLOUDIO_SENTRY_DSN_URL) {
  console.log(`Portal-V2-${process.env.REACT_APP_ENVIRONMENT}`);
  Sentry.init({
    dsn: process.env.REACT_APP_KLOUDIO_SENTRY_DSN_URL,
    environment: `Portal-V2-${process.env.REACT_APP_ENVIRONMENT}`,
    integrations: [
      new TracingIntegrations.BrowserTracing(),
      // {
      // routingInstrumentation: Sentry.reactRouterV5Instrumentation(
      //   createBrowserHistory()
      // ),
      // }
    ],
    tracesSampleRate: 0.2,
  });

  Sentry.configureScope((scope) => scope.setTag("process_name", "Portal-V2"));
} else {
  console.log("Portal-V2 - skipping sentry initialization ...");
}

class ErrorBoundary extends React.Component<any, any> {
  render() {
    return <Sentry.ErrorBoundary>{this.props.children}</Sentry.ErrorBoundary>;
  }
}

export default ErrorBoundary;

import React from "react";

import { Header } from "antd/lib/layout/layout";
import { useHistory } from "react-router-dom";

import style from "./style.module.scss";
interface InviteProps {
  children: any;
}

const Invite: React.FC<InviteProps> = ({ children }) => {
  const history = useHistory();
  return (
    <>
      <Header className={style.mainLayoutHeader}>
        <div className={style.mainLayoutSubHeader}>
          <div className={style.mainLayoutLogoContainer}>
            <img
              src="/images/kloudio-logo-small.svg"
              alt="kloudio Logo"
              style={{ height: "50px", width: "auto", cursor: "pointer" }}
              onClick={() => history.push("/dashboard")}
            />
          </div>
        </div>
      </Header>
      <div style={{ marginTop: "10px", padding: "5px" }}>{children}</div>
    </>
  );
};
export default Invite;

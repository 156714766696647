import React, { useEffect, useState } from "react";

import { Button } from "antd";
import Text from "antd/lib/typography/Text";
import { formatDistanceToNowStrict } from "date-fns";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { PlanType, useGetPlanQuery } from "../../typescript/graphql";
import style from "./style.module.scss";
interface UpgradePlanProps {
  main?: boolean;
}
const UpgradePlan: React.FC<UpgradePlanProps> = ({ main }) => {
  const { data } = useGetPlanQuery();
  const { show } = useIntercom();

  const [planName, setPlanName] = useState<PlanType>();
  const [expiresAt, setExpiresAt] = useState<Date>();
  const history = useHistory();

  useEffect(() => {
    if (data?.myAccount.plan.id) {
      setPlanName(data?.myAccount.plan.id);
      if (data.myAccount.expiresAt) {
        const expiryDate = new Date(data.myAccount.expiresAt);
        setExpiresAt(expiryDate);
      }
    }
  }, [data]);

  if (planName !== PlanType.Free_2021) return null;

  return (
    <div
      className={`${style.upgradePlanContainer} ${
        main ? style.mainWidth : style.otherWidth
      }`}
    >
      <div className={style.subtitle1}>Upgrade Today!</div>
      <div
        className={`${style.upgradeText} ${style.body1}`}
        hidden={!expiresAt}
      >
        <Text type="danger">
          {expiresAt && expiresAt > new Date() ? "Expires" : "Expired"}{" "}
          {expiresAt
            ? formatDistanceToNowStrict(new Date(expiresAt), {
                addSuffix: true,
              })
            : null}
        </Text>
      </div>
      <div className={`${style.upgradeText} ${style.body1}`}>
        You’re currently enjoying our free plan. Upgrade today to get more
        reports, data flows and real time scheduling.
      </div>
      <Button
        type="primary"
        onClick={() => {
          history.push("/billing");
        }}
      >
        Upgrade my Plan
      </Button>
    </div>
  );
};

export default UpgradePlan;

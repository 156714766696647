import React from "react";

import { WarningOutlined } from "@ant-design/icons";
import { useReactiveVar } from "@apollo/client";
import { Button, Dropdown, Menu } from "antd";
import { Typography } from "antd";

import { internalErrorsVar } from "../../apollo/cache";
const { Text } = Typography;

const Error: React.FC = () => {
  const internalErrors = useReactiveVar(internalErrorsVar);

  const menu = (
    <Menu>
      {internalErrors.map(({ title, path, extensions }) => (
        <Menu.Item key={path}>
          <Text copyable={{ text: JSON.stringify(extensions) }}>
            {title} ({path})
          </Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  return internalErrors?.length ? (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="text">
        <WarningOutlined style={{ color: "orange" }} />
      </Button>
    </Dropdown>
  ) : null;
};

export default Error;

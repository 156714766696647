import React from "react";

import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";

import MainHeader from "../Main/Header";
import style from "./style.module.scss";

const ConnectionLayout: React.FC<{ children: React.FC; title?: string }> = ({
  children,
}) => {
  return (
    <Layout className={style.connectionLayout}>
      <MainHeader />
      <div className={style.subHeader}>
        <h5>Add a Data Flow</h5>
      </div>
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default ConnectionLayout;

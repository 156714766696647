import { InMemoryCache, makeVar } from "@apollo/client";

import { OnboardingState } from "../typescript/graphql";

export type InternalError = {
  title: string;
  path: string;
  extensions: any;
};

export const OnboardingDefaultValue = {
  currentStep: 1,
  answers: {},
  completed: false,
};

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem("user"));
export const isPlanExpiredVar = makeVar(false);
export const onboardingStateVar = makeVar<OnboardingState>(
  OnboardingDefaultValue
);
export const internalErrorsVar = makeVar<InternalError[]>([]);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
      },
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

import { ReportPermission, ReportsRedux } from "../typescript/types";
import {
  addRemoveColumnReducer,
  addJoinReducer,
  selectAllReducer,
  saveFiltersReducer,
  deleteJoinReducer,
  updateJoinReducer,
  dimensionsMeasuresSaveReducer,
} from "./reportsReducers";

export const reportsSlice = createSlice({
  name: "report",
  initialState: {
    // for loading spinner
    pageLoading: false,
    // new report data:
    joins: [],
    reportName: "",
    sqlQueryRun: "",
    // data on main table:
    mainSelectedConnection: false,
    mainSchema: "",
    mainTable: "",
    mainColsSelected: [],
    renamedColumns: {},
    mainColumns: [],
    mainConditions: [],
    mainFilters: [],
    // for the 'Report List' component
    reportsDataList: [],
    // need to keep track of current meta data for joins
    previewColumns: [],
    currentColumns: [],
    currentSchema: "",
    currentTable: "",
    // needed to keep track of the columns that are available for a join
    secondaryTables: [],
    // if user edits the sql in 'SQL Editor', the report must switch to 'SQL View'
    showSqlView: false,
    sqlFromEditor: "",
    // Filters state, will change for GraphQL
    stagedFilters: [
      {
        schemaName: "",
        tableName: "",
        columnName: "",
        operator: "",
        paramValue: "",
        paramType: "text",
        dataType: "text",
        label: "",
        multiSelectValues: [],
        format: "",
        required: false,
        paramFinalValue: "",
        multiSelectSQL: "",
        multiSelectParams: [],
      },
    ],
    editReportData: false,
    mainAgg: [],
    isDataset: false,
    sqlTemplateVariables: [],
    reportPermission: ReportPermission.OWNED,
  } as ReportsRedux,
  reducers: {
    setReportState: (
      state: ReportsRedux,
      action: { payload: Partial<ReportsRedux> }
    ) => {
      return { ...state, ...action.payload };
    },
    addRemoveColumn: addRemoveColumnReducer,
    addJoin: addJoinReducer,
    selectAll: selectAllReducer,
    saveFilters: saveFiltersReducer,
    deleteJoin: deleteJoinReducer,
    updateJoin: updateJoinReducer,
    dimensionsMeasuresSave: dimensionsMeasuresSaveReducer,
  },
});

export const {
  setReportState,
  addRemoveColumn,
  addJoin,
  selectAll,
  saveFilters,
  deleteJoin,
  updateJoin,
  dimensionsMeasuresSave,
} = reportsSlice.actions;

// TRANSITION ALL BELOW TO GRAPHQL API:
export const saveQuery = () => {};
export const getReports = () => {};
export const getMultiSelectValues = () => {};

export default reportsSlice.reducer;

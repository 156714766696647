export enum PageRoutes {
  Home = "/",
  Login = "/auth/login",
  Register = "/auth/register",
  AcceptInvite = "/acceptInvite",
  openInvitation = "/openInvitation",
  Onboarding = "/onboarding",
  ForgotPassword = "/auth/forgot-password",
  ResetPassword = "/auth/resetPassword",

  Reports = "/reports",
  SQLReportNew = "/newReportSql",
  ReportNew = "/newReport",
  EditReport = "/editReport/:id",

  Source = "/source",
  SourceDetail = "/source/:id",
  SourceDetailEdit = "/source/:id/edit",
}

import React, { useCallback, useEffect, useState } from "react";

import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Dropdown, Avatar, Menu } from "antd";
import { Header } from "antd/lib/layout/layout";
import { useHistory } from "react-router-dom";

import { useSetFullstoryUser } from "../../hooks/useSetFullstoryUser";
import {
  useGetUserNameQuery,
  useLogoutMutation,
} from "../../typescript/graphql";
import Error from "./Errors";
import style from "./style.module.scss";
import { clearLoginCache } from "../../utils/user";

const MainHeader: React.FC = () => {
  const history = useHistory();
  const [logout] = useLogoutMutation();
  const setFullStoryUser = useSetFullstoryUser();

  const handleLogout = async () => {
    await clearLoginCache();
    await logout();
  };

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const toggleUserDropdown = useCallback(
    () => setUserDropdownOpen(!userDropdownOpen),
    [userDropdownOpen]
  );

  const menu = (
    <Menu>
      {/* <Menu.Item key="1">
        <Link to="/my-profile" className={style.profileLink}>
          My Profile
        </Link>
      </Menu.Item> */}
      <Menu.Item onClick={() => handleLogout()} key="2">
        <span>Sign Out</span>&nbsp;&nbsp;
        <LogoutOutlined />
      </Menu.Item>
    </Menu>
  );

  const { data } = useGetUserNameQuery();

  const me = data?.me;

  let initials = "";

  if (me) {
    const firstInitial = me?.firstName[0];
    const secondInitial = me?.lastName[0];
    initials = `${firstInitial}${secondInitial}`.toUpperCase();
  }

  useEffect(() => {
    if (me) {
      setFullStoryUser(me);
    }
  }, [me]);

  return (
    <Header className={style.mainLayoutHeader}>
      <div className={style.mainLayoutSubHeader}>
        <div className={style.mainLayoutLogoContainer}>
          <img
            src="/images/kloudio-logo-small.svg"
            alt="kloudio Logo"
            style={{ height: "50px", width: "auto", cursor: "pointer" }}
            onClick={() => history.push("/dashboard")}
          />
        </div>
        <div>
          {/* <Input placeholder="Search" prefix={<SearchOutlined />} /> */}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {process.env.REACT_APP_ENVIRONMENT !== "prod" ? <Error /> : ""}
        <Dropdown overlay={menu} trigger={["click"]}>
          <div
            className={style.mainLayoutSubHeader}
            onClick={() => toggleUserDropdown()}
          >
            <Avatar
              size={40}
              className={style.avatar}
              style={{ fontSize: "12px" }}
            >
              {initials}
            </Avatar>
            <div className={style.userName} onClick={toggleUserDropdown}>
              {me?.firstName} <DownOutlined />
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default MainHeader;

import React from "react";

import { Layout } from "antd";

import style from "./style.module.scss";

const AuthLayout: React.FC<{ children: React.FC; title?: string }> = ({
  children,
}) => {
  return <Layout className={style.authLayout}>{children}</Layout>;
};

export default AuthLayout;

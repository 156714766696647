import React, { useEffect } from "react";

import "../node_modules/antd/lib/style/index.less"; // antd core styles
import "./styles/themes/default.less"; // default theme antd components
import "./App.scss";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";

import client from "./apollo/client";
import store from "./redux/store";
import Router from "./router";

function App() {
  useEffect(() => {}, []);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ApolloProvider>
    </div>
  );
}

export default App;

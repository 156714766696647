import { notification } from "antd";

import {
  QueryJoinInput,
  QueryJoinType,
  QueryAggregationFn,
  QueryColumn,
  QueryAggregation,
} from "../typescript/graphql";
import {
  ReportsRedux,
  AddRemoveColumn,
  FilterOperations,
  OpTypeEnum,
  FilterToSaveType,
  FilterOperationsEnum,
  DimensionsMeasuresCols,
} from "../typescript/types";

export const addRemoveColumnReducer = (
  state: ReportsRedux,
  action: { payload: AddRemoveColumn }
) => {
  // if column is not in 'cols' or 'aggregations', we need to add the column. otherwise, we remove the column from either 'cols' or 'aggregations'
  const { name, schema, table, mainSchema, mainTable } = action.payload;
  if (mainSchema === schema && mainTable === table) {
    // update the mainColsSelected or mainAgg selected if change occuring on main table:
    if (state.mainColsSelected.some((el: QueryColumn) => el.name === name)) {
      state.mainColsSelected.splice(
        state.mainColsSelected.map((el: QueryColumn) => el.name).indexOf(name),
        1
      );
    } else if (
      state.mainAgg.some((el: QueryAggregation) => el.col.name === name)
    ) {
      state.mainAgg.splice(
        state.mainAgg.map((el: QueryAggregation) => el.col.name).indexOf(name),
        1
      );
    } else {
      state.mainColsSelected.push({ name });
    }
    return;
  }
  // otherwise we need to recursively traverse 'joins' array and find the table that needs updating:
  const addRemoveColumnRecursion = (
    arr: any[],
    inputSchema: string,
    inputTable: string,
    inputCol: string
  ) => {
    arr.forEach((el: { join: any; on: any; type: any }, i: number) => {
      const { schema, table } = el.join;
      if (inputSchema === schema && inputTable === table.name) {
        // we have found the table that needs updating
        if (
          el.join.table.cols.some((el: QueryColumn) => el.name === inputCol)
        ) {
          // if column is selected, deselect it (remove it from the array)
          el.join.table.cols.splice(
            el.join.table.cols
              .map((el: QueryColumn) => el.name)
              .indexOf(inputCol),
            1
          );
        } else if (
          el.join.table.aggregations?.some(
            (el: QueryAggregation) => el.col.name === inputCol
          )
        ) {
          el.join.table.aggregations.splice(
            el.join.table.aggregations
              .map((el: QueryAggregation) => el.col.name)
              .indexOf(inputCol),
            1
          );
        } else {
          // otherwise add the column to the selected array
          el.join.table.cols.push({ name: inputCol });
        }
      } else {
        // if the table (and schema) is not a match, keep searching for the join
        if (el.join.joins.length) {
          addRemoveColumnRecursion(
            el.join.joins,
            inputSchema,
            inputTable,
            inputCol
          );
        }
        // else: base case is hit, and recursion ends
      }
    });
  };
  addRemoveColumnRecursion(state.joins, schema, table, name);
};

export const addJoinReducer = (state: ReportsRedux, action: any) => {
  const {
    table1Selection,
    table2Selection,
    joinType,
    joinConditions,
    mainTable,
    mainSchema,
  } = action.payload;

  const opType: any = {
    "=": "eq",
    "!=": "ne",
    "<": "lt",
    "<=": "lte",
    ">": "gt",
    ">=": "gte",
  };

  const defaultNewJoin = {
    join: {
      schema: table2Selection.schema,
      table: { name: table2Selection.tableName, cols: [] },
      joins: [],
      conditions: [],
    },
    type: joinType.toLowerCase(),
    on: joinConditions.map((el: any) => {
      return {
        parentCol: { name: el.fromColumn },
        op: opType[el.operator],
        childCol: { name: el.toColumn },
      };
    }),
  };

  const iterate = (
    arr: any,
    table1Selection: any,
    table2Selection: any,
    joinType: any,
    joinConditions: any,
    defaultNewJoin: any
  ) => {
    arr.forEach((el: any) => {
      const { schema, table } = el.join;
      if (
        table1Selection.schema === schema &&
        table1Selection.tableName === table.name
      ) {
        el.join.joins.push(defaultNewJoin);
      } else {
        iterate(
          el.join.joins,
          table1Selection,
          table2Selection,
          joinType,
          joinConditions,
          defaultNewJoin
        );
      }
    });
  };

  if (
    table1Selection.tableName === mainTable &&
    table1Selection.schema === mainSchema
  ) {
    state.joins.push(defaultNewJoin);
    return;
  } else {
    iterate(
      state.joins,
      table1Selection,
      table2Selection,
      joinType,
      joinConditions,
      defaultNewJoin
    );
    return;
  }
};

export const selectAllReducer = (state: ReportsRedux, action: any) => {
  const { selectAll } = action.payload;

  // add / remove all columns from mainTable
  if (
    state.mainSchema === state.currentSchema &&
    state.mainTable === state.currentTable
  ) {
    if (selectAll) {
      state.mainColsSelected = state.mainColumns.map((el) => {
        return {
          name: el.name,
        };
      });
    } else {
      state.mainColsSelected = [];
    }
    return;
  }
  // add / remove all columns from joined table
  const selectAllRecursion = (arr: any[]) => {
    arr.forEach((el: { join: any; on: any; type: any }) => {
      const { schema, table } = el.join;
      if (state.currentSchema === schema && state.currentTable === table.name) {
        // we have found the table that needs updating
        if (selectAll) {
          el.join.table.cols = state.currentColumns.map((el) => {
            return {
              name: el.name,
            };
          });
        } else {
          el.join.table.cols = [];
        }
      } else {
        // if the table (and schema) is not a match, keep searching for the join
        if (el.join.joins.length) {
          selectAllRecursion(el.join.joins);
        }
        // else: base case is hit, and recursion ends
      }
    });
  };
  selectAllRecursion(state.joins);
};

// save Filters
export const saveFiltersReducer = (state: ReportsRedux, action: any) => {
  // need to first clear out all existing filters to handle case where filters have been deleted
  state.mainConditions = [];
  state.mainFilters = [];
  const resetFiltersRecursion = (arr: any[]) => {
    arr.forEach((el: { join: any; on: any; type: any }) => {
      el.join.conditions = [];
      el.join.filters = [];
      if (el.join.joins.length) {
        resetFiltersRecursion(el.join.joins);
      }
    });
  };
  resetFiltersRecursion(state.joins);

  const { filtersToSave } = action.payload;

  const FILTER_OP_TO_TYPES: FilterOperations = {
    Equals: "equals",
    "Not Equals": "notEquals",
    "Less Than": "lessThan",
    "Greater Than": "greaterThan",
    Contains: "contains",
    "Starts With": "startsWith",
    Between: "between",
    In: "in",
    "Not In": "notIn",
  };

  const conditionCallback = (el: FilterToSaveType) => {
    return {
      col: {
        name: el.columnName,
        label: el.label,
      },
      op: FILTER_OP_TO_TYPES[el.operator],
      val: el.paramValue.toString(),
    };
  };
  const filterCallback = (el: FilterToSaveType) => {
    return {
      col: {
        name: el.columnName,
        label: el.label,
      },
      op: FILTER_OP_TO_TYPES[el.operator],
      vals:
        (Array.isArray(el.paramValue) && el.paramValue.some((v) => v === "")) ||
        el.paramValue === ""
          ? null
          : el.paramValue,
    };
  };

  const filtersRecursion = (
    arr: QueryJoinInput[],
    filtersArr: FilterToSaveType[],
    key: string
  ) => {
    arr.forEach((el: { join: any; on: any; type: any }) => {
      const { schema, table } = el.join;
      if (`${schema}${table.name}` === key) {
        // we have found the table that needs updating
        // first update conditions w/ operators other than 'Between'
        el.join.conditions = filtersArr
          .filter((el: FilterToSaveType) => {
            if (
              el.operator !== FilterOperationsEnum.between &&
              el.operator !== FilterOperationsEnum.in &&
              el.operator !== FilterOperationsEnum.notIn
            ) {
              return true;
            }
            return false;
          })
          .map(conditionCallback);
        // then update filters w/ 'Between'
        el.join.filters = filtersArr
          .filter((el: FilterToSaveType) => {
            if (
              el.operator === FilterOperationsEnum.between ||
              el.operator === FilterOperationsEnum.in ||
              el.operator === FilterOperationsEnum.notIn
            ) {
              return true;
            }
            return false;
          })
          .map(filterCallback);
        return;
      } else {
        // if the table (and schema) is not a match, keep searching for the join
        if (el.join.joins.length) {
          filtersRecursion(el.join.joins, filtersArr, key);
        }
        // else: base case is hit, and recursion ends
      }
    });
  };

  for (const key in filtersToSave) {
    // add / remove filter from mainTable
    if (`${state.mainSchema}${state.mainTable}` === key) {
      state.mainConditions = filtersToSave[key]
        .filter((el: FilterToSaveType) => {
          if (
            el.operator !== FilterOperationsEnum.between &&
            el.operator !== FilterOperationsEnum.in &&
            el.operator !== FilterOperationsEnum.notIn
          ) {
            return true;
          }
          return false;
        })
        .map(conditionCallback);
      state.mainFilters = filtersToSave[key]
        .filter((el: FilterToSaveType) => {
          if (
            el.operator === FilterOperationsEnum.between ||
            el.operator === FilterOperationsEnum.in ||
            el.operator === FilterOperationsEnum.notIn
          ) {
            return true;
          }
          return false;
        })
        .map(filterCallback);
    } else {
      // if non-mainTable filter, iterate joins to find the table to update
      filtersRecursion(state.joins, filtersToSave[key], key);
    }
  }
};

export const deleteJoinReducer = (state: ReportsRedux, action: any) => {
  const { table2Selection } = action.payload;
  const iterate = (arr: QueryJoinInput[], table2Selection: any) => {
    arr.forEach((el: QueryJoinInput, i: number) => {
      const { schema, table } = el.join;
      if (
        table2Selection.schema === schema &&
        table2Selection.tableName === table.name
      ) {
        if (!el.join.joins?.length) {
          arr.splice(i, 1);
          // Need to remove the joined table from Secondary Tables array also
          for (let i = 0; i < state.secondaryTables.length; i += 1) {
            if (
              state.secondaryTables[i].schema === table2Selection.schema &&
              state.secondaryTables[i].tableName === table2Selection.tableName
            ) {
              state.secondaryTables.splice(i, 1);
              break;
            }
          }
          notification.success({
            message: "Delete Join Successful",
          });
        } else {
          notification.warning({
            message: "Invalid SQL Action",
            description:
              "By deleting this join, you will create invalid SQL as there are additional joins based off the table",
          });
        }
        return;
      } else {
        if (el.join.joins) {
          iterate(el.join.joins, table2Selection);
        }
      }
    });
  };
  iterate(state.joins, table2Selection);
};

export const updateJoinReducer = (state: ReportsRedux, action: any) => {
  const { table2Selection, joinConditions, joinType } = action.payload;
  const iterate = (
    arr: QueryJoinInput[],
    table2Selection: any,
    joinConditions: any,
    joinType: "Inner" | "Outer" | "Left" | "Right"
  ) => {
    arr.forEach((el: QueryJoinInput, i: number) => {
      const { schema, table } = el.join;
      if (
        table2Selection.schema === schema &&
        table2Selection.tableName === table.name
      ) {
        el.type = QueryJoinType[joinType];
        el.on = joinConditions.map((el: any) => {
          return {
            parentCol: { name: el.fromColumn },
            op: OpTypeEnum[el.operator],
            childCol: { name: el.toColumn },
          };
        });
        notification.success({
          message: "Updated Join Succesful",
        });
        return;
      } else {
        if (el.join.joins) {
          iterate(el.join.joins, table2Selection, joinConditions, joinType);
        }
      }
    });
  };
  iterate(state.joins, table2Selection, joinConditions, joinType);
};

export const dimensionsMeasuresSaveReducer = (
  state: ReportsRedux,
  action: any
) => {
  // add measuresRender items to mainAgg or aggregates, and add dimensions items to cols. Thus we need to first re-set all agg / cols array
  const { dimensionArr, measuresArr } = action.payload;
  const iterate = (
    arr: QueryJoinInput[],
    schema: string,
    table: string,
    name: string,
    aggregation: QueryAggregationFn | ""
  ) => {
    arr.forEach((el: QueryJoinInput) => {
      if (schema === el.join.schema && table === el.join.table.name) {
        // update table.groupBy or table.aggregations
        if (aggregation !== "") {
          el.join.table.aggregations?.push({
            func: aggregation,
            col: {
              name,
            },
          });
        } else {
          el.join.table.cols?.push({
            name,
          });
        }
      } else {
        if (el.join.joins) {
          iterate(el.join.joins, schema, table, name, aggregation);
        }
      }
    });
  };

  // iterate joins and set 'aggregations' and 'cols' to be empty array
  const resetAggAndCols = (arr: QueryJoinInput[]) => {
    arr.forEach((el: QueryJoinInput) => {
      el.join.table.aggregations = [];
      el.join.table.groupBy = [];
      el.join.table.cols = [];
      if (el.join.joins) {
        resetAggAndCols(el.join.joins);
      }
    });
  };
  resetAggAndCols(state.joins);

  // first reset mainAgg to an empty array
  state.mainAgg = [];
  state.mainColsSelected = [];

  // then iterate measuresArr / dimensionsArr and fill out aggregations / cols
  measuresArr.forEach((el: DimensionsMeasuresCols) => {
    const { schema, table, name, aggregation } = el;
    if (schema === state.mainSchema && table === state.mainTable) {
      state.mainAgg.push({
        func: aggregation,
        col: {
          name,
        },
      });
    } else {
      iterate(state.joins, schema, table, name, aggregation || "");
    }
  });

  dimensionArr.forEach((el: DimensionsMeasuresCols) => {
    const { schema, table, name } = el;
    if (schema === state.mainSchema && table === state.mainTable) {
      state.mainColsSelected.push({
        name,
      });
    } else {
      iterate(state.joins, schema, table, name, "");
    }
  });
};

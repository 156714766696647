import {
  User,
  QueryJoinType,
  QueryJoinConditionInput,
  QueryComparisonOp,
  ColumnType,
  QueryAggregationFn,
  ReportTemplateVarInput,
  MultiSelectOption,
} from "./graphql";

export interface userStateType {
  id: string;
  name: string;
  role: string;
  email: string;
  avatar: string;
  authorized: boolean;
  loading: boolean;
  incorrectLogin: boolean;
  connections: any;
  loginFailure: boolean;
  value: number;
  planEndDate: string;
  sources: any;
}

export interface stateType {
  menu: any;
  settings: any;
  user: any;
  catalog: any;
  router: any;
  onboarding: any;
  license: any;
  report: ReportsRedux;
  schedules: any;
}

export interface loginType {
  username: string;
  password: string;
}

export interface registerType {
  firstName: string;
  lastName: string;
  companyname: string;
  email: string;
  password: string;
}

export interface registerPayloadType {
  user: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    referrer: string | null;
    utm_source: string | null;
    utm_campaign: string | null;
    utm_medium: string | null;
    userObj: registerUserObjType;
    mode: string;
    versionedOnboardingState: any;
    hubspotCookie: string;
  };
  src: string;
  dataSource: string;
}

export interface UserResponse {
  id: number;
  username: String;
  email: String;
  firstName: String;
  lastName: String;
  isAdmin: Boolean;
  active?: Boolean;
}

export interface registerUserObjType {
  username: string;
  fullName: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  companySize: string;
  jobRole: string;
  department: string;
  phoneNumber: string;
}

export interface ForgotPasswordType {
  email: string;
}

export interface UserProfile {
  user: UserProfilePayload;
}

export interface UserProfilePayload {
  firstName: string;
  lastName: string;
  password?: string;
}

export interface GoogleOauthType {
  token: string;
  referrer: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  vendor: string;
  vendor_token: string;
  user: any;
  mode: string;
  extraFields: any;
}

export interface NextStep {
  question: string;
  answer: string;
}

export interface OnboardingBasicQuestions {
  title: string;
  selections: string[];
  dbField: string;
  img: string | undefined;
}

export interface UserOnboardingState {
  userVersion: number;
  versions: {
    1: OnboardingVersion1Data;
  };
}

export interface OnboardingVersion1Data {
  onboardingFinished: boolean;
  currentStep: number;
  onboardingAnswers: {
    useDatabases: string;
    useWebApps: string;
    useBI: string;
    priorDatabaseSetup: string;
    oneMainActivity: string;
  };
  onboardingCatalogRecs: string[];
}

export interface EditOrNewUserLicenseType {
  modalShow: boolean;
  setModalShow: (modalShow: boolean) => void;
  type: React.ReactNode | string;
  okText: React.ReactNode;
  user: User;
  callback: (user: User) => Promise<void>;
  datatestid?: string;
  numAdmins?: number;
}

export interface NewUserDataType {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export interface UpdateUserDataType {
  firstname: string;
  lastname: string;
  email: string;
  userrole: string;
  editedUserId: string;
  editLicenseId: number;
}

export interface CompanySetting {
  enableRoles: boolean;
  enableKloudML: boolean;
  enableEmailSharing: boolean;
  enableSMTP: boolean;
  smtpSettings: {
    auth: {
      pass: string;
      user: string;
    };
    host: string;
    port: string;
    secure: boolean;
  };
}

export enum OnboardingSteps {
  Welcome = 1,
  Warehouse,
  Source,
  Team,
  End,
}

export interface OpTypes {
  "=": String;
  "!=": String;
  ">": String;
  "<": String;
}

export enum ReportPermission {
  "READ" = "read",
  "EDIT" = "edit",
  "OWNED" = "owned",
}

export interface ReportsRedux {
  pageLoading: boolean;
  joins: any[];
  reportName: string;
  sqlQueryRun: string;
  mainSelectedConnection: any;
  mainSchema: string;
  mainTable: string;
  mainColsSelected: any[];
  renamedColumns: any;
  mainColumns: any[];
  mainConditions: any[];
  mainFilters: any[];
  reportsDataList: any[];
  previewColumns: any[];
  currentColumns: any[];
  currentSchema: string;
  currentTable: string;
  secondaryTables: any[];
  showSqlView: boolean;
  sqlFromEditor: string;
  stagedFilters: StagedFilters[];
  editReportData: any;
  mainAgg: any[];
  isDataset: boolean;
  sqlTemplateVariables: (ReportTemplateVarInput & {
    multiSelectOptions?: MultiSelectOption[] | null;
  })[];
  reportPermission: ReportPermission;
}

export interface StagedFilters {
  schemaName: string;
  tableName: string;
  columnName: string;
  operator: string;
  paramValue?: string | string[];
  paramType: keyof DataTypesStringMulti;
  dataType: keyof DataTypesString;
  label: string;
  multiSelectValues: any[];
  format: string;
  required: boolean;
  paramFinalValue: string;
  multiSelectSQL: string;
  multiSelectParams: any[];
}

export interface FilterTableData extends StagedFilters {
  key: number;
  index: number;
}

export interface AddRemoveColumn {
  name: string;
  table: string;
  schema: string;
  mainTable: string;
  mainSchema: string;
}

export interface DataSourceElement {
  id: number;
  name: string;
  type: string;
}

export interface TablesElement {
  name: string;
}

export interface ColumnsElement {
  name: string;
  type: keyof DataTypesString;
  schema: string;
  table: string;
}

export interface FilterOperations {
  Equals: string;
  "Not Equals": string;
  "Less Than": string;
  "Greater Than": string;
  Contains: string;
  "Starts With": string;
  Between: string;
  In: string;
  "Not In": string;
}

export enum FilterOperationsEnum {
  equals = "Equals",
  notEquals = "Not Equals",
  lessThan = "Less Than",
  lessThanOrEquals = "lessThanOrEquals",
  greaterThan = "Greater Than",
  greaterThanOrEquals = "greaterThanOrEquals",
  contains = "Contains",
  startsWith = "Starts With",
  between = "Between",
  in = "In",
  notIn = "Not In",
  endsWith = "endsWith",
}

export enum DataTypesEnum {
  number = "Number",
  text = "Text",
  json = "JSON",
  time = "Date",
  boolean = "Boolean",
  unknown = "Unknown",
}

export enum DataTypesEnumMulti {
  number = "Number",
  text = "Text",
  json = "JSON",
  time = "Date",
  boolean = "Boolean",
  "Multi-Select" = "Multi-Select",
}

export enum DataTypesIconEnum {
  number = "/images/dataIcons/number.svg",
  text = "/images/dataIcons/text.svg",
  json = "/images/dataIcons/json.svg",
  time = "/images/dataIcons/date.svg",
  boolean = "/images/dataIcons/boolean.svg",
}

export interface DataTypesString {
  number: string;
  text: string;
  json: string;
  time: string;
  boolean: string;
}

export interface DataTypesStringMulti extends DataTypesString {
  "Multi-Select": string;
}

type DataTypeConvert<Type> = {
  [Property in keyof Type]: any;
};

export type DataTypesArray = DataTypeConvert<DataTypesStringMulti>;

export interface SecondaryTables {
  tableName: string;
  schema: string;
  availableCols: ColumnsElement[];
}

export enum JoinOperationsEnum {
  eq = "=",
  ne = "!=",
  lt = "<",
  lte = "<=",
  gt = ">",
  gte = ">=",
}

export enum JoinTypeEnum {
  inner = "Inner",
  outer = "Outer",
  left = "Left",
  right = "Right",
}

export interface JoinToEditType {
  left: {
    schema: string;
    table: string;
  };
  right: {
    schema: string;
    table: string;
  };
  type: QueryJoinType;
  on: QueryJoinConditionInput[];
  joinNumber: number;
}

export enum OpTypeEnum {
  "=" = QueryComparisonOp.Eq,
  "!=" = QueryComparisonOp.Ne,
  "<" = QueryComparisonOp.Lt,
  "<=" = QueryComparisonOp.Lte,
  ">" = QueryComparisonOp.Gt,
  ">=" = QueryComparisonOp.Gte,
}

export interface FilterToSaveType {
  columnName: string;
  operator: keyof FilterOperations;
  paramValue?: any;
  label: string;
}

export interface DimensionsMeasuresCols {
  name: string;
  table: string;
  schema: string;
  type: ColumnType | "";
  aggregation?: QueryAggregationFn | "";
}

import React, { useEffect, useState } from "react";

import {
  DashboardOutlined,
  InteractionOutlined,
  TableOutlined,
  TeamOutlined,
  CalendarOutlined,
  UserAddOutlined,
  DatabaseOutlined,
  ApartmentOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useReactiveVar } from "@apollo/client";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { isPlanExpiredVar } from "../../apollo/cache";
import { useGetUserRoleQuery } from "../../typescript/graphql";
import style from "./style.module.scss";
interface RouteListType {
  title: string;
  icon: any;
  url: string;
  child?: RouteListType[];
}
const list: RouteListType[] = [
  {
    title: "Dashboard",
    icon: <DashboardOutlined />,
    url: "/dashboard",
    child: [],
  },
  {
    title: "Connections",
    icon: <InteractionOutlined />,
    url: "/connections",
  },
  {
    title: "Data Flows",
    icon: <ApartmentOutlined />,
    url: "/flows",
  },
  {
    title: "Reports",
    icon: <TableOutlined />,
    url: "/reports",
  },
  {
    title: "Schedules",
    icon: <CalendarOutlined />,
    url: "/schedules",
  },
  // {
  //   title: "Templates",
  //   icon: <CloudUploadOutlined style={{ fontSize: "18.75px" }} />,
  //   url: "/templates",
  // },
  // {
  //   title: "Settings",
  //   icon: <SettingOutlined style={{ fontSize: "18.75px" }} />,
  //   url: "/settings",
  // },
  // {
  //   title: "Billing & Invoices",
  //   icon: <FileTextOutlined style={{ fontSize: "18.75px" }} />,
  //   url: "/billing",
  // },
  // {
  //   title: "FAQ & Help",
  //   icon: <QuestionCircleOutlined style={{ fontSize: "18.75px" }} />,
  //   url: "/faq",
  // },
];

if (process.env.REACT_APP_FEATURE_FLAG_DATASET === "true") {
  list.push({
    title: "Datasets",
    icon: <DatabaseOutlined />,
    url: "/datasets",
  });
}

interface LeftMenuProps {
  collapsed: boolean;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ collapsed }) => {
  const { pathname } = useLocation();
  const [routeList, setRouteList] = useState(list);
  const { data } = useGetUserRoleQuery();
  const isPlanExpired = useReactiveVar(isPlanExpiredVar);

  useEffect(() => {
    if (data?.me?.isSiteAdmin) {
      setRouteList([
        ...list,
        {
          title: "Admin",
          icon: <UserAddOutlined style={{ fontSize: "18.75px" }} />,
          url: "/admin",
        },
      ]);
    } else if (data?.me?.isAdmin) {
      const admin = {
        title: "Admin",
        icon: <UserAddOutlined style={{ fontSize: "18.75px" }} />,
        url: "/admin",
        child: [
          {
            title: "Users",
            icon: <TeamOutlined style={{ fontSize: "18.75px" }} />,
            url: "/users",
          },
          {
            title: "Teams",
            icon: <TeamOutlined />,
            url: "/teams",
          },
          {
            title: "Billing",
            icon: <FileTextOutlined style={{ fontSize: "18.75px" }} />,
            url: "/billing",
          },
          {
            title: "Settings",
            icon: <FileTextOutlined style={{ fontSize: "18.75px" }} />,
            url: "/admin-settings",
          },
        ],
      };
      setRouteList([...list, admin]);
    }
  }, [data]);

  const generateMenuItems = () => {
    return routeList.map(({ url, icon, title, child }) => {
      if (child && child.length) {
        return (
          <Menu.SubMenu
            key={url}
            disabled={isPlanExpired}
            icon={<span className={style.menuIcon}>{icon}</span>}
            title={title}
          >
            {child.map(({ url, icon, title }) => {
              return (
                <Menu.Item
                  key={url}
                  className={style.menuIcon}
                  disabled={isPlanExpired}
                >
                  <Link to={url}>
                    <span>{title}</span>
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
      const menu = (
        <Menu.Item
          key={url}
          style={{
            height: "48px",
            display: "flex",
            alignItems: "center",
          }}
          icon={<span className={style.menuIcon}>{icon}</span>}
          disabled={isPlanExpired}
        >
          <Link to={url} className={style.menuItemLink}>
            {title}
          </Link>
        </Menu.Item>
      );

      return menu;
    });
  };

  return (
    <Menu
      mode="inline"
      style={{
        backgroundColor: "#FAFAFA",
        width: collapsed ? "80px" : "200px",
        border: "none",
      }}
      selectedKeys={[pathname, "/" + pathname.split("/")[1]]}
    >
      {generateMenuItems()}
    </Menu>
  );
};

export default LeftMenu;

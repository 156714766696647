import {
  isLoggedInVar,
  OnboardingDefaultValue,
  onboardingStateVar,
} from "../apollo/cache";
import client from "../apollo/client";

export async function clearLoginCache() {
  localStorage.removeItem("user");
  isLoggedInVar(false);
  onboardingStateVar(OnboardingDefaultValue);
  await client.resetStore();
}

import React, { useState } from "react";

import { Layout, PageHeader } from "antd";

import LeftMenu from "../LeftMenu";
import UpgradePlan from "../UpgradePlan";
import MainHeader from "./Header";
import style from "./style.module.scss";

const { Sider, Content } = Layout;

export interface MainLayoutProps {
  children: React.FC;
  title: string;
  hideSidebar?: boolean;
  hideSubHeader?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  title,
  hideSidebar,
  hideSubHeader,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className={style.mainLayout}>
      <MainHeader />
      <Layout>
        <Sider
          width={collapsed ? "0px" : "200px"}
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          breakpoint="xl"
          hidden={hideSidebar}
        >
          <div className={style.mainSider}>
            <LeftMenu collapsed={collapsed} />
            {collapsed === false && <UpgradePlan main={true} />}
          </div>
        </Sider>
        <Content className={style.mainContent}>
          {!hideSubHeader && (
            <PageHeader
              className={style.sitePageHeader}
              title={title}
              style={{ height: "60px" }}
            />
          )}
          <div className={style.sitePageContainer}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import reportsSlice from "./reportsSlice";

export const reducer = combineReducers({
  report: reportsSlice,
});

const store = configureStore({
  reducer,
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type GetAppState = typeof store.getState;
